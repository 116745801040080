import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteSubUserTheme,
  getBillingStats,
  getCreditsRequest,
  getDomain,
  getMasterStats,
  getMasterStatsGraph,
  getOrganizationLoginCheckvalid,
  getPerformance,
  getSubUsers,
  getSubUserTheme,
  postAddSubuserInvite,
  postChangePasswordSubuser,
  postCreateSubUserInternal,
  postLoginAsSubuser,
  postOrganizationLogin,
  postOrganizationLoginGoogle,
  postSubUsersStats,
  postUpdateCreditLimit,
  putCreditsRequestUpdate,
  putSubuserAccountUpdate,
  putUpdateSubUserProfile,
  putUpdateSubUserTheme,
} from "../api/masterApi";

export const useGetSubUsers = (params) => {
  return useQuery({
    queryKey: ["getSubUsers", params],
    queryFn: () => getSubUsers(params),
  });
};

export const useGetSubUsersInfinity = ({ email }, config) => {
  return useInfiniteQuery({
    queryKey: ["getSubUsersInfinity", email],
    queryFn: ({ pageParam = 0 }) =>
      getSubUsers({ index: pageParam, email: email }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (
        allPages?.flatMap((page) => page?.sub_users)?.length <
        lastPage?.total_sub_users
      ) {
        return allPages?.flatMap((page) => page?.sub_users)?.length;
      }
    },
    ...config,
  });
};

export const useGetDomain = (config) => {
  return useQuery({
    queryKey: ["getDomain"],
    queryFn: () => getDomain(),
    ...config,
  });
};

export const usePostCreateSubUserInternal = () => {
  return useMutation({
    mutationKey: ["postCreateSubUserInternal"],
    mutationFn: (data) => postCreateSubUserInternal(data),
  });
};
export const useGetPerformance = (order) => {
  return useQuery({
    queryKey: ["getPerformance", order], // Include `order` in the query key
    queryFn: () => getPerformance(order), // Pass `order` to the function
  });
};

export const usePostAddSubuserInvite = () => {
  return useMutation({
    mutationKey: ["postAddSubuserInvite"],
    mutationFn: (data) => postAddSubuserInvite(data),
  });
};

export const useGetOrganizationLoginCheckvalid = (id, config) => {
  return useQuery({
    queryKey: ["getOrganizationLoginCheckvalid", id],
    queryFn: () => getOrganizationLoginCheckvalid(id),
    ...config,
  });
};

export const usePostOrganizationLogin = () => {
  return useMutation({
    mutationKey: ["postOrganizationLogin"],
    mutationFn: ({ id, data }) => postOrganizationLogin(id, data),
  });
};

export const usePostOrganizationLoginGoogle = () => {
  return useMutation({
    mutationKey: ["postOrganizationLogin"],
    mutationFn: ({ id, data }) => postOrganizationLoginGoogle(id, data),
  });
};

export const useGetMasterStats = () => {
  return useQuery({
    queryKey: ["getMasterStats"],
    queryFn: () => getMasterStats(),
  });
};

export const usePostLoginAsSubuser = () => {
  return useMutation({
    mutationKey: ["postLoginAsSubuser"],
    mutationFn: (data) => postLoginAsSubuser(data),
  });
};

export const usePostChangePasswordSubuser = () => {
  return useMutation({
    mutationKey: ["postChangePasswordSubuser"],
    mutationFn: (data) => postChangePasswordSubuser(data),
  });
};

export const useGetMasterStatsGraph = (sort, config) => {
  return useQuery({
    queryKey: ["getMasterStatsGraph", sort],
    queryFn: () => getMasterStatsGraph(sort),
    retry: 0,
    ...config,
  });
};

export const usePostSubUsersStats = () => {
  return useMutation({
    mutationKey: ["postSubUsersStats"],
    mutationFn: (data) => postSubUsersStats(data),
  });
};

export const usePutSubuserAccountUpdate = () => {
  return useMutation({
    mutationKey: ["putSubuserAccountUpdate"],
    mutationFn: ({ id, data }) =>
      putSubuserAccountUpdate({ id: id, data: data }),
  });
};

export const usePostUpdateCreditLimit = () => {
  return useMutation({
    mutationKey: ["postUpdateCreditLimit"],
    mutationFn: ({ id, data }) => postUpdateCreditLimit({ id: id, data: data }),
  });
};

export const useGetBillingStats = () => {
  return useQuery({
    queryKey: ["getBillingStats"],
    queryFn: () => getBillingStats(),
  });
};

export const usePutUpdateSubUserProfile = () => {
  return useMutation({
    mutationKey: ["putUpdateSubUserProfile"],
    mutationFn: ({ id, data }) => putUpdateSubUserProfile({ id, data }),
  });
};

export const useGetSubUserTheme = (id, config) => {
  return useQuery({
    queryKey: ["getSubUserTheme", id],
    queryFn: () => getSubUserTheme(id),
    ...config,
  });
};

export const usePutUpdateSubUserTheme = () => {
  return useMutation({
    mutationKey: ["putUpdateSubUserTheme"],
    mutationFn: ({ id, data }) => putUpdateSubUserTheme({ id, data }),
  });
};

export const useDeleteSubUserTheme = () => {
  return useMutation({
    mutationKey: ["deleteSubUserTheme"],
    mutationFn: (id) => deleteSubUserTheme(id),
  });
};

export const useGetCreditsRequest = (params, config) => {
  return useQuery({
    queryKey: ["getCreditsRequest"],
    queryFn: () => getCreditsRequest(params),
    ...config,
  });
};

export const usePutCreditsRequestUpdate = () => {
  return useMutation({
    mutationKey: ["putCreditsRequestUpdate"],
    mutationFn: ({ id, data }) =>
      putCreditsRequestUpdate({ id: id, data: data }),
  });
};
