import React, { useState } from "react";
import Layout from "../component/Layout";
import {
  ArrowDownUp,
  CircleCheckBig,
  CircleDashed,
  Filter,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Skeleton from "react-loading-skeleton";
import { dayFormatter, timeFormatter } from "../../../utils/formatter";
import { useGetBuyCardHistory } from "../../../query/usePayment";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { Button } from "../../../components/ui/button";
import {
  useGetReviewsAnalyticsNonPosted,
  useGetReviewsAnalyticsPosted,
} from "../../../query/useReview";
import ReviewAnalyticsAudio from "./components/ReviewAnalyticsAudio";
import RaReviewsModal from "./components/RaReviewsModal";
import RaViewFormModal from "./components/RaViewFormModal";
import Google from "../../../assets/reviewservices/google.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

const redirects = [
  {
    name: "Google",
    value: "google",
    img: Google,
  },
  {
    name: "Yelp",
    value: "yelp",
    img: Yelp,
  },
  {
    name: "Trustpilot",
    value: "trustpilot",
    img: TrustPilot,
  },
  {
    name: "Booking",
    value: "booking",
    img: Booking,
  },
  {
    name: "Airbnb",
    value: "airbnb",
    img: Airbnb,
  },
  {
    name: "Trip Advisor",
    value: "tripadvisor",
    img: TripAdvisor,
  },
];

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const ReviewsAnalytics = () => {
  const { darkMode } = useDarkMode();
  const [tab, setTab] = useState(true);
  const [redirectsFromApi, setRedirectsFromApi] = useState([]);
  const [redirect, setRedirect] = useState("all");
  const [index, setIndex] = useState(0);
  const [isOpenReviews, setIsOpenReviews] = useState(false);
  const [openReviewsId, setOpenReviewsId] = useState();
  const [verifiedPosted, setVerifiedPosted] = useState(false);

  // const { data: reviewsAnalytics, isLoading: reviewsAnalyticsLoading } =
  //   useGetReviewsAnalytics(
  //     {
  //       limit: 10,
  //       index: index,
  //       posted: tab,
  //       redirect: redirect,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         setRedirectsFromApi(res.redirect);
  //       },
  //     }
  //   );

  const {
    data: reviewsAnalyticsPosted,
    isLoading: reviewsAnalyticsPostedLoading,
  } = useGetReviewsAnalyticsPosted(
    {
      limit: 10,
      index: index,
      redirect: redirect === "all" ? "" : redirect,
      verified_posted: verifiedPosted,
    },
    {
      onSuccess: (res) => {
        setRedirectsFromApi(res.redirect);
      },
      enabled: tab === true,
    }
  );

  const {
    data: reviewsAnalyticsNonPosted,
    isLoading: reviewsAnalyticsNonPostedLoading,
  } = useGetReviewsAnalyticsNonPosted(
    {
      limit: 10,
      index: index,
      redirect: redirect,
    },
    {
      // onSuccess: (res) => {
      //   setRedirectsFromApi(res.redirect);
      // },
      enabled: tab === false,
    }
  );

  const reviewsAnalytics = tab
    ? reviewsAnalyticsPosted
    : reviewsAnalyticsNonPosted;

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const reviewPlatforms = (platform) => {
    switch (platform) {
      case "google":
        return <img src={Google} title="Google" />;
      case "yelp":
        return <img src={Yelp} title="Yelp" />;
      case "trustpilot":
        return <img src={TrustPilot} title="TrustPilot" />;
      case "airbnb":
        return <img src={Airbnb} className="w-5" title="Airbnb" />;
      case "booking":
        return <img src={Booking} title="Booking" />;
      case "tripadvisor":
        return <img src={TripAdvisor} className="w-5" title="TripAdvisor" />;
    }
  };

  return (
    <Layout title={"Reviews Analytics"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px]">
        <div className="flex items-center justify-between flex-wrap gap-5">
          {tab ? (
            // <div className="font-pro font-medium text-sm  gap-3 flex items-center justify-start w-full lg:max-w-md overflow-x-auto scrollbar-thin">
            //   {redirects
            //     ?.filter((item) =>
            //       redirectsFromApi
            //         ?.map((item) => item?.redirected)
            //         ?.includes(item?.value)
            //     )
            //     ?.map((item, i) => (
            //       <button
            //         className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg flex items-center gap-2 flex-shrink-0 ${redirect === item?.value && "border-newPrimary text-newPrimary"}`}
            //         onClick={() => {
            //           if (redirect === item?.value) {
            //             setRedirect("");
            //           } else {
            //             setRedirect(item?.value);
            //           }

            //           setIndex(0);
            //         }}
            //         key={item?.value}
            //       >
            //         <img src={item?.img} alt="" className="w-5 h-5" />
            //         {item?.name}

            //         <span className="font-bold size-[22px] bg-[#f0f5ff] flex items-center justify-center rounded-full flex-shrink-0">
            //           {
            //             redirectsFromApi?.find(
            //               (red) => red?.redirected === item?.value
            //             )?.count
            //           }
            //         </span>
            //       </button>
            //     ))}
            //   {/* <button
            //     className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg flex items-center gap-2 flex-shrink-0 ${verifiedPosted && "border-newPrimary text-newPrimary"}`}
            //     onClick={() => {
            //       if (verifiedPosted) {
            //         setVerifiedPosted(false);
            //       } else {
            //         setVerifiedPosted(true);
            //       }

            //       setIndex(0);
            //     }}
            //   >
            //     Verified Posted
            //   </button> */}
            // </div>
            <div className="flex items-center gap-5">
              <Select
                value={redirect}
                onValueChange={(value) => setRedirect(value)}
              >
                <SelectTrigger className="w-[250px] font-poppins">
                  <SelectValue placeholder="Select redirect" />
                </SelectTrigger>
                <SelectContent className="font-poppins">
                  <SelectItem value={"all"}>
                    <div className="flex items-center gap-2 justify-between">
                      All redirects
                      {/* <div className="font-bold size-[22px] bg-[#f0f5ff] flex items-center justify-center rounded-full flex-shrink-0">
                      {redirectsFromApi?.reduce(
                        (acc, item) => acc + item?.count,
                        0
                      )}
                    </div> */}
                    </div>
                  </SelectItem>
                  {redirects
                    ?.filter((item) =>
                      redirectsFromApi
                        ?.map((item) => item?.redirected)
                        ?.includes(item?.value)
                    )
                    ?.map((item, i) => (
                      <SelectItem value={item?.value} key={item?.value}>
                        <div className="flex items-center gap-2 justify-between">
                          <img src={item?.img} alt="" className="w-5 h-5" />
                          {item?.name}

                          <div className="font-bold size-[22px] bg-[#f0f5ff] flex items-center justify-center rounded-full flex-shrink-0">
                            {
                              redirectsFromApi?.find(
                                (red) => red?.redirected === item?.value
                              )?.count
                            }
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              <button
                className={`font-poppins flex-shrink-0 text-sm text-newPrimary font-medium bg-[#dbe7ff] px-5 h-10 flex justify-center items-center rounded-[8px] ${verifiedPosted && "border border-newPrimary "}`}
                onClick={() => {
                  if (verifiedPosted) {
                    setVerifiedPosted(false);
                  } else {
                    setVerifiedPosted(true);
                  }
                }}
              >
                Verified Reviews -{" "}
                <span className="text-base font-semibold">
                  {reviewsAnalyticsPosted?.total_verified_posted}
                </span>
              </button>
            </div>
          ) : (
            <div className="hidden lg:block"></div>
          )}
          {/* {tab ? (
            <div className="font-poppins flex-shrink-0 text-sm text-newPrimary font-medium bg-[#dbe7ff] px-5 h-10 flex justify-center items-center rounded-[8px]">
              Verified Reviews -{" "}
              <span className="text-base font-semibold">
                {reviewsAnalyticsPosted?.total_verified_posted}
              </span>
            </div>
          ) : (
            <div></div>
          )} */}
          <div className="font-pro font-medium text-sm  gap-3 flex items-center self-end">
            <button
              className={`border rounded-lg h-10 py-2 px-3 bg-white dark:bg-darkLightBg ${tab === true && "border-newPrimary text-newPrimary"}`}
              onClick={() => {
                setTab(true);
                setIndex(0);
              }}
            >
              Redirected{" "}
              {tab && (
                <span className="font-bold">
                  - {reviewsAnalytics?.totol_results}
                </span>
              )}
            </button>
            <button
              className={`border rounded-lg h-10 py-2 px-3 bg-white dark:bg-darkLightBg ${tab === false && " border-newPrimary text-newPrimary"}`}
              onClick={() => {
                setTab(false);
                setIndex(0);
              }}
            >
              Non-Redirected
              {tab === false && (
                <span className="font-bold">
                  - {reviewsAnalytics?.totol_results}
                </span>
              )}
            </button>
            {/* <button
            className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg flex items-center gap-2 ${tab === "filters" && "border-newPrimary text-newPrimary"}`}
            // onClick={() => setTab("filters")}
          >
            <Filter className="size-5" /> Filters
          </button>
          <button
            className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg flex items-center gap-2 ${tab === "sort" && " border-newPrimary text-newPrimary"}`}
            // onClick={() => setTab("sort")}
          >
            <ArrowDownUp className="size-5" /> Sort
          </button> */}
          </div>
        </div>

        {reviewsAnalytics?.data?.length <= 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-[60vh] dark:bg-darkLightBg rounded-lg font-pro">
            <img
              src="/illustrations/reviews.svg"
              alt="Questions"
              className="w-[100px] h-[100px] mx-auto mt-10"
            />
            <h1 className="mt-[18px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
              Generate Review
            </h1>
            <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
              No Reviews Generated Yet!
            </p>
          </div>
        ) : (
          <div className="rounded-xl overflow-hidden mt-10">
            <Table>
              <TableHeader>
                <TableRow>
                  {tab === false ? (
                    <>
                      {" "}
                      {[
                        "Date",

                        "Reviews",

                        // "IP Address",
                        "Location",
                        "Device Type",
                        "Details",
                      ].map((item) => (
                        <TableHead
                          key={item}
                          className="text-[#808080] dark:text-white text-[15px]"
                        >
                          {item}
                        </TableHead>
                      ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      {[
                        "Platform",
                        "Ratings",
                        "Date",
                        "Verified Post",
                        "Reviews",
                        "Name",
                        // "IP Address",
                        "Location",
                        "Device Type",
                        "Details",
                      ].map((item) => (
                        <TableHead
                          key={item}
                          className="text-[#808080] dark:text-white text-[15px]"
                        >
                          {item}
                        </TableHead>
                      ))}
                    </>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody className="divide-y">
                {(
                  tab
                    ? reviewsAnalyticsPostedLoading
                    : reviewsAnalyticsNonPostedLoading
                ) ? (
                  <TableRow>
                    {tab === false ? (
                      <>
                        {new Array(5).fill("").map((_, i) => (
                          <TableCell
                            key={i}
                            className="bg-white dark:bg-darkBg"
                          >
                            <Skeleton
                              height={30}
                              baseColor={darkMode && "#262626"}
                            />
                          </TableCell>
                        ))}
                      </>
                    ) : (
                      <>
                        {new Array(9).fill("").map((_, i) => (
                          <TableCell
                            key={i}
                            className="bg-white dark:bg-darkBg"
                          >
                            <Skeleton
                              height={30}
                              baseColor={darkMode && "#262626"}
                            />
                          </TableCell>
                        ))}
                      </>
                    )}
                  </TableRow>
                ) : reviewsAnalytics?.data?.length > 0 ? (
                  reviewsAnalytics?.data?.map((charge, i) => (
                    <>
                      <TableRow key={i} className="text-[15px]">
                        <TableCell
                          className={`capitalize ${tab === false && "hidden"}`}
                        >
                          {charge?.redirected
                            ? reviewPlatforms(charge?.redirected)
                            : "-"}
                        </TableCell>
                        <TableCell className={` ${tab === false && "hidden"}`}>
                          {charge?.star_rating
                            ? wordsToNumber[charge?.star_rating] + "/5"
                            : "-"}
                        </TableCell>
                        <TableCell className="">
                          <div
                            className="cursor-default"
                            title={
                              charge?.created_at &&
                              timeFormatter(charge?.created_at)
                            }
                          >
                            {charge?.created_at &&
                              dayFormatter(charge?.created_at)}
                          </div>
                        </TableCell>
                        <TableCell className={` ${tab === false && "hidden"}`}>
                          {charge?.verified_posted ? (
                            <p className="text-[#34a853] flex items-center gap-2 font-poppins  text-xs">
                              <CircleCheckBig className="size-4" />{" "}
                            </p>
                          ) : (
                            <p className="text-[#ffaf19] flex items-center gap-2 font-poppins  text-xs">
                              {" "}
                              <CircleDashed className="size-4 " />{" "}
                            </p>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="secondary"
                            size="sm"
                            className="text-xs"
                            onClick={() => {
                              setIsOpenReviews(true);
                              setOpenReviewsId(charge?.id);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell className={` ${tab === false && "hidden"}`}>
                          {charge?.display_name || "-"}
                        </TableCell>
                        {/* <TableCell>{charge?.ip_address}</TableCell> */}
                        <TableCell>{charge?.location}</TableCell>
                        <TableCell>{charge?.device_type}</TableCell>
                        <TableCell>
                          {/* {charge?.voice_review ? (
                          <ReviewAnalyticsAudio
                            url={charge?.original_voice?.voice}
                          />
                        ) : ( */}
                          <Button
                            variant="secondary"
                            size="sm"
                            className="text-xs"
                            onClick={() => {
                              setIsOpenReviews(false);
                              setOpenReviewsId(charge?.id);
                            }}
                          >
                            View
                          </Button>
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                      {isOpenReviews && charge?.id === openReviewsId && (
                        <RaReviewsModal
                          isOpen={charge?.id === openReviewsId}
                          setIsOpen={setOpenReviewsId}
                          data={charge}
                        />
                      )}
                      {!isOpenReviews && charge?.id === openReviewsId && (
                        <RaViewFormModal
                          isOpen={charge?.id === openReviewsId}
                          setIsOpen={setOpenReviewsId}
                          data={charge}
                        />
                      )}
                    </>
                  ))
                ) : null}
              </TableBody>
            </Table>
          </div>
        )}
        {reviewsAnalytics?.totol_results > 10 && (
          <div className="flex justify-between items-center mt-5">
            {index <= 0 ? (
              <div></div>
            ) : (
              <Button
                className="disabled:hidden"
                onClick={handlePrev}
                disabled={index <= 0}
              >
                Prev
              </Button>
            )}
            <Button
              className=" disabled:hidden"
              onClick={handleNext}
              disabled={index + 10 > reviewsAnalytics?.totol_results}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ReviewsAnalytics;
