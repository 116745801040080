import Layout from "../dashboard/component/Layout";
import {
  ArrowLeft,
  Check,
  Ellipsis,
  LogIn,
  Pencil,
  Plus,
  PlusSquare,
  Search,
  SquareAsterisk,
  Trash2,
  X,
} from "lucide-react";
import { useNavigate } from "react-router";
import {
  useGetSubUsers,
  useGetSubUsersInfinity,
  usePostLoginAsSubuser,
} from "../../query/useMaster";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import IconButton from "../dashboard/component/IconButton";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from "../../components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import EditUserModal from "./components/EditUserModal";
import { toast } from "sonner";
import Loading, { LogoLoading } from "../../components/loading/loading";
import UserDetail from "./components/UserDetail";
import { cn } from "../../utils/cn";
import { useInView } from "react-intersection-observer";
import useDebounce from "../../hooks/useDebounce";
import { Input } from "../../components/ui/input";
import ConfirmLoginUserModal from "./components/ConfirmLoginUserModal";

import { TriangleAlert } from "lucide-react";
import ChangePasswordModal from "./components/ChangePasswordModal";
const Users = () => {
  const navigate = useNavigate();
  const { inView, ref } = useInView();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(0);
  const [isOpenEditModal, setIsOpenEditModal] = useState(0);
  const [email, setEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [index, setIndex] = useState(0);
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const [isChangePasswordModal, setIsChangePasswordModal] = useState();

  const debouncedValue = useDebounce(email, 500);

  const { data: subusers, isLoading: subusersLoading } = useGetSubUsers({
    // email: debouncedValue,
    index: index,
  });

  const { data, hasNextPage, fetchNextPage } = useGetSubUsersInfinity({
    email: debouncedValue,
  });

  const { mutate: loginAsSubuser, isLoading: loginAsSubuserLoading } =
    usePostLoginAsSubuser();

  const handleLoginAsSubuser = (id) => {
    const masterToken = localStorage.getItem("token");
    const masterUser = localStorage.getItem("user");
    if (masterToken) {
      sessionStorage.setItem("masterToken", masterToken);
      sessionStorage.setItem("masterUser", masterUser);
    }
    loginAsSubuser(
      {
        pk: id,
      },
      {
        onSuccess: (data) => {
          if (data.token) {
            localStorage.setItem("token", data.token);
            navigate("/dashboard", { replace: true });
          } else {
            toast.success(data.message);
            navigate(`/register-entity?profileId=${data.profile_uuid}`, {
              replace: true,
            });
          }
        },
        onError: (error) => {
          if (error.response.status === 500) {
            toast.error(
              `Something went wrong, try again later ${error.response.status}`
            );
          } else {
            toast.error(error.response.data.detail);
          }
        },
      }
    );
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const flattenedData = data?.pages?.flatMap((page) => page?.sub_users);

  const handleSelectedUser = (item) => {
    setSelectedUser(item);
    setIsSelectedUser(true);
  };

  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    setSelectedUser(
      flattenedData?.find((item) => item?.id === selectedUser?.id)
    );
  }, [flattenedData]);

  if (loginAsSubuserLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Users"} key={"Users"}>
      <div className="flex flex-col w-full dark:bg-darkLightBg rounded-[16px] py-5 min-h-[calc(100vh-150px)] 3xl:min-h-[calc(100vh-150px)] px-5 lg:px-[30.2px] mt-[20px]">
        {!isSelectedUser && (
          <>
            <Button
              className="w-full md:w-[120px] bg-newPrimary text-white flex items-center justify-center rounded-lg py-2 px-3 gap-x-3 text-sm font-pro font-medium mb-5"
              onClick={() => {
                navigate("/organisation/users/create");
              }}
            >
              <PlusSquare className="size-4" />
              Add User
            </Button>

            {subusersLoading ? (
              <Loading height={"50vh"} />
            ) : (
              <div className="rounded-xl overflow-hidden">
                <Table>
                  <TableHeader>
                    <TableRow>
                      {[
                        "Name",
                        "Business Location",
                        "Credit Limit",
                        "Credits Used",
                        "Status",
                        "",
                      ]?.map((item) => (
                        <TableHead key={item}>{item}</TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody className="divide-y">
                    <>
                      {subusers?.sub_users?.map((item) => (
                        <>
                          <TableRow
                            key={item?.id}
                            className="hover:bg-accent dark:hover:bg-accent cursor-pointer"
                          >
                            <TableCell onClick={() => handleSelectedUser(item)}>
                              <div className="flex items-center">
                                <img
                                  className="w-6 h-6 rounded-full lg:mr-[9px] mr-[3px]"
                                  src={
                                    item?.profile?.profile_picture
                                      ? item?.profile?.profile_picture
                                      : "/new-logo.svg"
                                  }
                                  alt=""
                                  referrerPolicy="no-referrer"
                                />
                                <p>
                                  {item?.first_name + " " + item?.last_name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell onClick={() => handleSelectedUser(item)}>
                              <p>{item?.entity?.location || "-"}</p>
                            </TableCell>
                            <TableCell onClick={() => handleSelectedUser(item)}>
                              <p>{item?.credit_limit}</p>
                            </TableCell>
                            <TableCell onClick={() => handleSelectedUser(item)}>
                              <p>{item?.profile?.usage}</p>
                            </TableCell>
                            {/* <TableCell onClick={() => setSelectedUser(item)}>
                              <p>
                                {item?.total_amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: subusers?.currency,
                                })}
                              </p>
                            </TableCell> */}

                            <TableCell onClick={() => handleSelectedUser(item)}>
                              {item?.profile?.is_completed ? (
                                <div className="w-[90px] h-6 rounded-[7px] bg-[#affebf] text-[#014b40] text-xs text-center flex justify-center items-center">
                                  Active
                                </div>
                              ) : (
                                <div className="w-[90px] h-6 rounded-[7px] bg-[#ffd6a4] text-[#5e4200] text-xs text-center flex justify-center items-center">
                                  Pending
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              <DropdownMenu>
                                <DropdownMenuTrigger>
                                  <Ellipsis className="size-4 text-[#595959]" />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <div className="font-pro text-xs">
                                    <Button
                                      variant="ghost"
                                      className="gap-2 text-black dark:text-white dark:hover:text-black justify-start w-full "
                                      onClick={() =>
                                        handleLoginAsSubuser(item?.id)
                                      }
                                    >
                                      <LogIn className="size-4 " />
                                      Login as user
                                    </Button>
                                    <DropdownMenuSeparator />
                                    <Button
                                      variant="ghost"
                                      className="gap-2 text-black dark:text-white dark:hover:text-black justify-start w-full "
                                      onClick={() =>
                                        setIsChangePasswordModal(item?.id)
                                      }
                                    >
                                      <SquareAsterisk className="size-4 " />
                                      Change Password
                                    </Button>
                                    <DropdownMenuSeparator />
                                    <Button
                                      variant="ghost"
                                      className="gap-2 text-black dark:text-white dark:hover:text-black justify-start w-full"
                                      onClick={() =>
                                        setIsOpenEditModal(item?.id)
                                      }
                                    >
                                      <Plus className="size-4" />
                                      Add credits
                                    </Button>
                                    {/* <DropdownMenuSeparator />
                            <Button
                              variant="ghost"
                              className="gap-2 text-[#737373] justify-start w-full"
                            >
                              <Trash2 className="size-4" />
                              Remove
                            </Button> */}
                                  </div>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                          {item?.id === isOpenEditModal && (
                            <EditUserModal
                              isOpenModal={item?.id === isOpenEditModal}
                              setIsOpenModal={setIsOpenEditModal}
                              creditLimit={item?.credit_limit}
                              id={item?.id}
                              key={item?.id}
                            />
                          )}
                          {item?.id === isChangePasswordModal && (
                            <ChangePasswordModal
                              openModal={item?.id === isChangePasswordModal}
                              setOpenModal={setIsChangePasswordModal}
                              id={item?.id}
                            />
                          )}
                          {/* {item?.id === isOpenConfirmModal && (
                            <ConfirmLoginUserModal
                              isOpenModal={item?.id === isOpenConfirmModal}
                              setIsOpenModal={setIsOpenConfirmModal}
                              handleAction={() =>
                                handleLoginAsSubuser(item?.id)
                              }
                            />
                          )} */}
                        </>
                      ))}
                      {/* <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {subusers?.sub_users?.reduce(
                            (sum, item) => sum + item?.profile?.usage_max,
                            0
                          )}
                        </TableCell>
                        <TableCell>
                          {subusers?.sub_users?.reduce(
                            (sum, item) => sum + item?.profile?.usage,
                            0
                          )}
                        </TableCell>
                        <TableCell>
                          {subusers?.sub_users
                            ?.reduce((sum, item) => sum + item?.total_amount, 0)
                            ?.toLocaleString("en-US", {
                              style: "currency",
                              currency: subusers?.currency,
                            })}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow> */}
                    </>
                  </TableBody>
                </Table>
              </div>
            )}
            {subusers?.total_sub_users > 10 && (
              <div className="flex justify-between items-center mt-5">
                <Button
                  className="disabled:hidden"
                  onClick={handlePrev}
                  disabled={index <= 0}
                >
                  Prev
                </Button>
                <Button
                  className=" disabled:hidden"
                  onClick={handleNext}
                  disabled={index + 10 > subusers?.total_sub_users}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
        {isSelectedUser && (
          <div className="flex gap-5 h-full flex-wrap font-pro">
            <div className="w-full lg:w-[25%] h-full space-y-5 lg:sticky  lg:top-28">
              <div className="flex gap-3">
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full "
                  onClick={() => {
                    setSelectedUser();
                    setIsSelectedUser(false);
                  }}
                >
                  <ArrowLeft className="size-5" />
                </Button>

                <div className="relative w-full text-[#615E83]">
                  <Input
                    type="text"
                    placeholder="Search"
                    className="w-full rounded-[8px] px-5 py-2 bg-white h-[45px]"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Search className="w-5 h-5 absolute right-5 top-1/2 -translate-y-1/2" />
                </div>
              </div>
              <div className="w-full font-pro bg-white dark:bg-darkBg rounded-[8px] divide-y lg:h-[calc(100vh-300px)] h-[300px] overflow-y-auto scrollbar-thin px-2.5 py-2.5 overflow-x-hidden">
                {flattenedData?.map((item) => (
                  <div
                    className={cn(
                      "flex items-center justify-between py-2.5 pl-3 cursor-pointer",
                      selectedUser?.id === item?.id &&
                        "bg-newPrimary  rounded-lg "
                    )}
                    key={item?.id}
                    onClick={() => handleSelectedUser(item)}
                  >
                    <div className="flex items-center gap-x-3">
                      <div>
                        <img
                          src={
                            item?.profile?.profile_picture || "/new-logo.svg"
                          }
                          referrerPolicy="no-referrer"
                          alt=""
                          className="w-[30px] h-[30px] rounded-full flex-shrink-0"
                        />
                      </div>
                      <div className="font-pro">
                        <p
                          className={cn(
                            "text-sm ",
                            selectedUser?.id === item?.id
                              ? "text-white"
                              : "text-[#252323] dark:text-white"
                          )}
                        >
                          {item?.first_name + " " + item?.last_name}
                        </p>
                        <p
                          className={cn(
                            "text-[10px] ",
                            selectedUser?.id === item?.id
                              ? "text-white"
                              : "text-[#888]"
                          )}
                        >
                          {item?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {!email && hasNextPage && (
                  <div ref={ref} className="text-center pt-3">
                    <p className="text-center text-xs text-[#888]">
                      Loading ...
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-[72%]  rounded-[12px] mt-5 lg:mt-0">
              {selectedUser?.entity ? (
                <UserDetail
                  user={selectedUser}
                  currency={subusers?.currency}
                  key={selectedUser?.id}
                />
              ) : (
                <div className="flex justify-center items-center  min-h-[70vh]">
                  <div className="w-full max-w-[600px] rounded-[8px]  text-[#2f1400] dark:text-white p-[0.8125rem] mb-3 flex  gap-4 items-center mx-auto justify-center">
                    <TriangleAlert className="h-7 w-7 stroke-white fill-[#2f1400]" />

                    <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-5">
                      <p className="font-pro font-normal  text-sm">
                        <span className="font-medium dark:font-semibold">
                          Profile registration is incomplete
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Users;
