import {
  ChevronDown,
  ChevronLeftCircle,
  ChevronRightCircle,
  Info,
  Link,
  Pencil,
  QrCode,
  Trash2,
  Ellipsis,
  SquarePlus,
  Copy,
  TriangleAlert,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import Layout from "../component/Layout";
import AddStaffModal from "./components/AddStaffModal";
import { useQueryClient } from "@tanstack/react-query";
import Joyride, { STATUS } from "react-joyride";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Switch } from "../../../components/ui/switch";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useGetIntegrations,
  useOnboarded,
  usePostToggleStaffManagement,
  useUser,
} from "../../../query/useAuthentication";
import {
  useClickGetMagicQr,
  useDeleteEmployee,
  useGetActiveDefaultMagicLink,
  useGetDefaultQuestionSets,
  useGetEmployeesList,
  useGetMagicLink,
  usePostAddDefaultQuestionSets,
  usePostDefaultMagicLink,
  usePostEmployeeEnable,
  usePostResetMagicLink,
  useGetEmployeesStats,
} from "../../../query/useEmployees";
import { useGetQuestionSets } from "../../../query/useEntity";
import CustomTooltipForJoyride from "../component/CustomTooltipForJoyride";
import IconButton from "../component/IconButton";
import { DeleteModal } from "../component/modal";
import QrContainer from "../component/QrContainer";
import EditStaffModal from "./components/EditStaffModal";
import LinkModal from "./components/LinkModal";
import Google from "../../../assets/reviewservices/google.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import { MagiclinkIllustrations } from "../../onboarding/components/illustrations/MagiclinkIllustrations";
import DownloadStatsModal from "./components/DownloadStatsModal";
const Staffs = () => {
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const questionSetRef = useRef(null);
  const queryClient = useQueryClient();
  const { darkMode } = useDarkMode();
  const [index, setIndex] = useState(0);
  const [isOpenAddStaffModal, setIsOpenAddStaffModal] = useState(false);
  const [isOpenEditStaffModal, setIsOpenEditStaffModal] = useState();
  const [toUpdateData, setToUpdateData] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isActiveStaffManagement, setIsActiveStaffManagement] = useState(false);
  const [file, setFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [slicedNumber, setSlicedNumber] = useState(0);
  const [employeeId, setEmployeeId] = useState();
  const [isOpenQuestionSetBox, setIsOpenQuestionSetBox] = useState(false);
  const [selectedDefaultQuestionSet, setSelectedDefaultQuestionSet] =
    useState();
  const [originalDefaultQuestionSet, setOriginalDefaultQuestionSet] =
    useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [seeMoreLabel, setSeeMoreLabel] = useState();
  const [seeMoreTags, setSeeMoreTags] = useState();
  const [loadingMagicLink, setLoadingMagicLink] = useState();
  const [isOpenQrContainer, setIsOpenQrContainer] = useState(false);
  const [qr, setQr] = useState();
  const [selectedSet, setSelectedSet] = useState(null);
  const [isOpenLink, setIsOpenLink] = useState(null);
  const { mutate: onboarded, isLoading: onboardedLoading } = useOnboarded();
  const { pathname } = useLocation();
  const joyRideStepsForMagiclink = [
    {
      title: "Default Magiclink",
      content:
        "Create a default question set to access its Magiclink via the copy icon to the right of Magiclink button above",
      target: ".select-set",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Enable Magiclinks",
      content: "Turn on the toggle to add Magiclinks",
      target: ".enable-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Add Magiclinks",
      content: "Click to add Magiclinks for your team",
      target: ".add-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Reset",
      content: "Reset the Magiclink to the default question set",
      target: ".reset-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Question sets",
      content:
        "View Magiclinks arranged according to the designated question sets",
      target: ".set",
      spotlightClicks: true,
      referrer: "Complete",
      referrerFunc: function () {
        onboarded(
          {},
          {
            onSuccess: (res) => {
              toast.success(res.message);
              setStep((prev) => {
                return { ...prev, run: false };
              });
            },
            onError: (err) => {
              if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message);
              } else {
                toast.error(
                  `Something went wrong, try again later ${err.response.status}`
                );
              }
            },
          }
        );
      },
    },
    // {
    //   title: "Set Default",
    //   content:
    //     "This sets the MagicLink as default for quick access via the copy icon in the top right corner.",
    //   target: ".set-default",
    //   spotlightClicks: true,
    // },
    // {
    //   title: "Edit Magiclinks",
    //   content:
    //     "Click to edit the MagicLink's name, role, tags, question set, and more.",
    //   target: ".edit-magiclink",
    //   spotlightClicks: true,
    // },
    // {
    //   title: "Activate",
    //   content:
    //     "Toggle to display the name and role on the MagicLink's review page.",
    //   target: ".activate-magiclink",
    //   spotlightClicks: true,
    // },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForMagiclink,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "verify-email" && pathname === "/dashboard/magiclinks") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });

    setCsvArray(newArray);
  };

  const baseUrl = window.location.origin;
  const userInfo =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const {
    data,
    isLoading: employeeListLoading,
    isFetching: employeeListFetching,
    refetch,
  } = useGetEmployeesList();

  const { data: active, refetch: refetchActive } =
    useGetActiveDefaultMagicLink();

  const { mutate: postToggleStaffManagement, isLoading: isToggleLoading } =
    usePostToggleStaffManagement();

  const { mutate: deleteEmployee, isLoading: deleteEmployeeLoading } =
    useDeleteEmployee();

  const { mutate: toggleEmployeeEnable, isLoading: isToggleEmployeeLoading } =
    usePostEmployeeEnable();

  const { mutate: resetMagicLink, isLoading: isResetMagicLinkLoading } =
    usePostResetMagicLink();

  const { refetch: refetchUser } = useUser({
    onSuccess: (res) => {
      setIsActiveStaffManagement(
        res.profile.user_settings_staff_management_enabled
      );
    },
  });

  const { refetch: refetchDefaultQuestionSet } = useGetDefaultQuestionSets({
    onSuccess: (res) => {
      setSelectedDefaultQuestionSet(res?.data);
      setOriginalDefaultQuestionSet(res?.data);
    },
  });

  const {
    mutate: addDefaultQuestionSets,
    isLoading: addDefaultQuestionSetsLoading,
  } = usePostAddDefaultQuestionSets();

  const { data: magicLink } = useGetMagicLink();
  const { mutate: defaultMagicLink, isLoading: defaultMagicLinkLoading } =
    usePostDefaultMagicLink();
  const { data: questionSets, isLoading: isQuestionSetsLoading } =
    useGetQuestionSets();

  const { mutate: getMagicQr, isLoading: getMagicQrLoading } =
    useClickGetMagicQr();

  const { refetch: refetchStats, isLoading } = useGetEmployeesStats({
    enabled: false,
  });

  const [isIntegrated, setIsIntegrated] = useState(true);

  const handleCopy = (employeeCode) => {
    if (userInfo?.entity?.magic_link) {
      navigator.clipboard.writeText(
        baseUrl +
          "/" +
          userInfo?.entity?.magic_link?.split("?")?.[0] +
          `?employee=${employeeCode}`
      );
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const handleGetQr = (employeeCode) => {
    getMagicQr(employeeCode, {
      onSuccess: (res) => {
        setQr(res);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    });
    setIsOpenQrContainer(true);
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const handleDeleteEmployee = () => {
    deleteEmployee(deleteId, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        } else {
          toast.success("Link has been deleted successfully");
        }
        refetch();
        setIsOpenDeleteModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text); // plugged in here
      };

      reader.readAsText(file);
      setIsOpenPreviewModal(true);
    }
    e.target.value = "";
  };

  const toggle = () => {
    postToggleStaffManagement(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchUser();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleEmployee = (employeeId) => {
    setEmployeeId(employeeId);
    toggleEmployeeEnable(employeeId, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetch();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleResetMagicLink = () => {
    resetMagicLink(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries("getMagicLink");
          queryClient.invalidateQueries("getMagicQr");
          refetchActive();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDefaultMagicLink = (id) => {
    setLoadingMagicLink(id);
    defaultMagicLink(id, {
      onSuccess: (res) => {
        toast.success(res.message);
        queryClient.invalidateQueries("getMagicLink");
        queryClient.invalidateQueries("getMagicQr");
        refetchActive();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddDefaultQuestionSet = (set) => {
    addDefaultQuestionSets(
      {
        set: set,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchDefaultQuestionSet();
          // setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const { data: defaultQuestionSet } = useGetDefaultQuestionSets();

  useGetIntegrations({
    onSuccess: (res) => {
      setIsIntegrated(res?.value);
    },
  });

  useOnClickOutside({
    ref: questionSetRef,
    setIsOpen: setIsOpenQuestionSetBox,
  });

  const defaultSet = questionSets?.find((item) => item?.is_default);
  useEffect(() => {
    setSelectedSet(defaultSet?.id);
  }, [defaultSet]);

  const scrollByAmount = (amount) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: amount, behavior: "smooth" });
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (scrollRef.current) {
      setIsOverflowing(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  };

  const reviewPlatforms = (platform) => {
    switch (platform) {
      case "Google":
        return <img src={Google} />;
      case "Yelp":
        return <img src={Yelp} />;
      case "Trustpilot":
        return <img src={TrustPilot} />;
      case "Airbnb":
        return <img src={Airbnb} className="w-5" />;
      case "Booking":
        return <img src={Booking} />;
      case "Tripadvisor":
        return <img src={TripAdvisor} className="w-5" />;
    }
  };

  const handleCopyId = (id) => {
    if (id) {
      navigator.clipboard.writeText(id);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [questionSets]);

  if (
    defaultMagicLinkLoading ||
    isResetMagicLinkLoading ||
    isToggleLoading ||
    isToggleEmployeeLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Magic Links"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-120px)] px-5 lg:px-[30.2px]">
        <DownloadStatsModal
          setOpenModal={setIsOpenModal}
          openModal={isOpenModal}
        />
        <div className="flex flex-wrap items-center justify-between gap-5 mb-5">
          <div className="flex flex-wrap items-center gap-5">
            {magicLink?.flag === 1 ? (
              <div className="w-4 h-4 rounded-full flex-shrink-0 bg-[#73df07]"></div>
            ) : (
              <div className="w-4 h-4 rounded-full flex-shrink-0 bg-red-500"></div>
            )}
            <p className="text-lg font-medium font-pro">Default Magiclink</p>
            <div
              className="lg:w-[200px] w-full relative text-[#615E83] dark:text-white h-10 select-set"
              ref={questionSetRef}
            >
              <button
                className="px-6 h-full rounded-[8px] focus:outline-none flex items-center justify-between w-full bg-white dark:bg-darkBg cursor-pointer border border-white dark:border-darkBg"
                onClick={() => setIsOpenQuestionSetBox((prev) => !prev)}
                // disabled={!isEditable}
              >
                <p className="text-sm font-medium text-[#878787] font-pro">
                  {selectedDefaultQuestionSet
                    ? questionSets?.find(
                        (item) => item?.id === selectedDefaultQuestionSet
                      )?.name === "default"
                      ? "Default"
                      : questionSets?.find(
                          (item) => item?.id === selectedDefaultQuestionSet
                        )?.name
                    : "Default"}
                </p>

                {addDefaultQuestionSetsLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-t-2 border-b-2 border-[#2a49b8] rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <ChevronDown
                    className={`w-5 h-5 ${isOpenQuestionSetBox && "rotate-180"} transition-all duration-200`}
                  />
                )}
              </button>
              {isOpenQuestionSetBox && (
                <div className="w-full p-5 rounded-[7px] absolute top-12 left-0 bg-white dark:bg-darkBg dark:border-none border space-y-5 z-[60] text-left lg:h-[200px] overflow-y-auto scrollbar-thin shadow-md capitalize">
                  {questionSets
                    ?.filter?.((item) => item?.is_default)
                    .map((item) => (
                      <div
                        key={item?.id}
                        className="flex items-center justify-between group"
                      >
                        <p
                          className="w-full cursor-pointer group-hover:text-secondary text-sm font-medium"
                          onClick={() => {
                            setIsOpenQuestionSetBox(false);
                            handleAddDefaultQuestionSet(item?.id);
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  {questionSets
                    ?.filter?.((item) => !item?.is_default)
                    .map((item) => (
                      <div
                        key={item?.id}
                        className="flex items-center justify-between group"
                      >
                        <p
                          className="w-full cursor-pointer group-hover:text-secondary text-sm font-medium"
                          onClick={() => {
                            setIsOpenQuestionSetBox(false);
                            handleAddDefaultQuestionSet(item?.id);
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="flex-shrink-0 text-sm text-newPrimary font-medium bg-[#dbe7ff] px-5 h-10 flex justify-center items-center rounded-[8px] font-poppins">
              Verified Reviews -{" "}
              <span className="text-base font-semibold">
                {defaultQuestionSet?.verified_reviews}
              </span>
            </div>

            <div className="flex items-center gap-3 flex-wrap">
              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-[8px] border border-newPrimary bg-white text-newPrimary dark:bg-darkLightBg hover:bg-newPrimary hover:text-white transition-all duration-200  font-pro reset-magiclink"
                onClick={handleResetMagicLink}
                disabled={isResetMagicLinkLoading}
              >
                {/* {isResetMagicLinkLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : ( */}
                <>
                  <p className="text-sm font-normal">Reset to default</p>
                </>
                {/* )} */}
              </button>
              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-[8px] border border-newPrimary bg-white text-newPrimary dark:bg-darkLightBg hover:bg-newPrimary hover:text-white transition-all duration-200  font-pro reset-magiclink"
                onClick={() => setIsOpenModal(true)}
              >
                {/* {isResetMagicLinkLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : ( */}
                <>
                  <p className="text-sm font-normal">Download Stats</p>
                </>
                {/* )} */}
              </button>
            </div>
          </div>
          {/* <input
            type="file"
            hidden
            accept=".csv"
            ref={inputRef}
            onChange={handleFileChange}
          /> */}
          {/* <button
            className="w-fit flex items-center gap-x-3 px-5 py-3 rounded-xl border border-secondary bg-secondary text-white hover:bg-white hover:text-secondary transition-all duration-200"
            onClick={() => {
              inputRef?.current.click();
            }}
          >
            <p>Import from file</p>
            <Import />
          </button> */}
          {/* <div className="w-fit place-content-end">
            <div className="flex items-center gap-x-3 enable-magiclink">
              <p className={`font-pro text-base font-medium`}>
                Enable Magiclink
              </p>

              <Switch
                checked={isActiveStaffManagement}
                disabled={isToggleLoading}
                onCheckedChange={toggle}
              />
            </div>
          </div> */}
        </div>
        {employeeListLoading || isQuestionSetsLoading ? (
          <Loading height="50vh" />
        ) : (
          <>
            <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-3 mt-5">
              {isOverflowing && (
                <button
                  onClick={() => scrollByAmount(-100)}
                  className="hidden lg:block"
                >
                  <ChevronLeftCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
                </button>
              )}

              {!isQuestionSetsLoading && questionSets?.length > 0 && (
                <div
                  className="flex items-center w-full lg:w-3/4 gap-3 bg-white dark:bg-darkBg px-5 py-2 rounded-[8px] overflow-x-auto scrollbar-none relative set font-pro text-[#525252]"
                  ref={scrollRef}
                >
                  {questionSets
                    ?.filter((item) => item?.is_default)
                    .map((item, i) => (
                      <React.Fragment key={item?.id}>
                        <button
                          className={`px-3 py-2 rounded-full flex-shrink-0 text-[#525252] dark:text-darkGray ${selectedSet === item?.id ? "bg-[#f0f5ff] dark:bg-darkLightBg text-newPrimary dark:text-newPrimary font-medium " : ""} text-[15px]`}
                          onClick={() => setSelectedSet(item?.id)}
                        >
                          {item?.name === "default" ? "Default" : item?.name}
                        </button>
                        {questionSets?.length !== i + 1 && (
                          <div className="border-l-2 h-7"></div>
                        )}
                      </React.Fragment>
                    ))}
                  {questionSets
                    ?.filter((item) => !item?.is_default)
                    .map((item, i) => (
                      <React.Fragment key={item?.id}>
                        <button
                          className={`px-3 py-2 rounded-full flex-shrink-0 ${selectedSet === item?.id ? "bg-[#f0f5ff] dark:bg-darkLightBg text-newPrimary font-medium" : "dark:text-darkGray"}  text-[#525252] text-[15px]`}
                          onClick={() => setSelectedSet(item?.id)}
                        >
                          {item?.name}
                        </button>
                        {questionSets?.length !== i + 2 && (
                          <div className="border-l-2 h-7"></div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              )}

              {isOverflowing && (
                <button
                  onClick={() => scrollByAmount(100)}
                  className="hidden lg:block"
                >
                  <ChevronRightCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
                </button>
              )}

              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-[8px] border border-newPrimary bg-newPrimary text-white dark:hover:bg-darkLightBg hover:bg-white hover:text-newPrimary transition-all duration-200 w-[150px] font-pro add-magiclink"
                onClick={() => {
                  setIsOpenAddStaffModal(true);
                  setToUpdateData();
                }}
                disabled={!isActiveStaffManagement || !isIntegrated}
              >
                <SquarePlus />
                <p className="text-sm font-normal">Add Magiclink</p>
              </button>
            </div>
            {/* <div className="flex justify-end mt-7">
              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-[8px] border border-newPrimary bg-newPrimary text-white dark:hover:bg-darkLightBg hover:bg-white hover:text-newPrimary transition-all duration-200 w-[150px] font-pro add-magiclink"
                onClick={() => {
                  setIsOpenAddStaffModal(true);
                  setToUpdateData();
                }}
                disabled={!isActiveStaffManagement}
              >
                <SquarePlus />
                <p className="text-sm font-normal">Add Magiclink</p>
              </button>
            </div> */}
            <div className="overflow-x-auto mt-7 scrollbar-thin">
              {!employeeListLoading &&
              data?.filter((item) => item?.default_set === selectedSet)
                ?.length > 0 ? (
                <div className="rounded-xl overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="text-[15px] text-[#808080]">
                        {/* <TableHead className="text-[#808080] dark:text-white">
                          Set Default
                        </TableHead> */}
                        <TableHead className="text-[#808080] dark:text-white">
                          Name
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white">
                          Role
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white">
                          Tags
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white">
                          Redirects
                        </TableHead>
                        {/* <TableHead className="text-[#808080] dark:text-white">
                          Question Set
                        </TableHead> */}
                        <TableHead className="text-[#808080] dark:text-white w-fit">
                          <div className="flex items-center gap-1">
                            Reviews{" "}
                            <div title="No. of reviews redirected">
                              <Info className="text-[#888] size-4" />
                            </div>
                          </div>
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white w-fit">
                          <div className="flex items-center gap-1">
                            Verified Reviews{" "}
                            <div title="No. of reviews verified on platforms">
                              <Info className="text-[#888] size-4" />
                            </div>
                          </div>
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white">
                          Link
                        </TableHead>
                        <TableHead className="text-[#808080] dark:text-white">
                          Activate
                        </TableHead>
                        {isActiveStaffManagement && (
                          <TableHead className="text-[#808080] dark:text-white"></TableHead>
                        )}
                      </TableRow>
                    </TableHeader>
                    <TableBody className="divide-y">
                      {employeeListLoading ? (
                        <TableRow>
                          {new Array(10).fill("").map((_, i) => (
                            <TableCell key={i}>
                              <Skeleton
                                height={30}
                                baseColor={darkMode && "#262626"}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ) : data?.length > 0 ? (
                        <>
                          {data
                            ?.filter((item) => item?.is_enable)
                            ?.filter(
                              (item) => item?.default_set === selectedSet
                            )
                            ?.map((item, i) => (
                              <>
                                <TableRow
                                  className="bg-white dark:bg-darkBg text-[15px]"
                                  key={item?.id}
                                >
                                  {/* <TableCell>
                                    <div
                                      className={`flex items-center gap-x-2 ${i === 0 && "set-default"}`}
                                    >
                                      <input
                                        type="radio"
                                        name="default"
                                        className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"}`}
                                        id={item?.id}
                                        onChange={() =>
                                          handleDefaultMagicLink(item?.id)
                                        }
                                        checked={
                                          item?.id === active?.employee_id
                                        }
                                      />
                                      <p>{item?.employee_code}</p>
                                    </div>
                                  </TableCell> */}

                                  <TableCell>
                                    <p>{item?.name}</p>
                                  </TableCell>
                                  <TableCell>
                                    <div className="max-w-[120px] w-full">
                                      {item?.label?.length > 14 ? (
                                        <>
                                          {seeMoreLabel === item?.id ? (
                                            <div>
                                              <p className="break-words">
                                                {item?.label}
                                              </p>
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreLabel()
                                                }
                                              >
                                                See Less
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p className="line-clamp-1">
                                                {item?.label?.slice(0, 14)}...
                                              </p>
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreLabel(item?.id)
                                                }
                                              >
                                                See More
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <p className="break-words">
                                          {item?.label}
                                        </p>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex items-center gap-1 max-w-[150px] w-full flex-wrap">
                                      {item?.tags?.length > 3 ? (
                                        <>
                                          {seeMoreTags === item?.id ? (
                                            <>
                                              {item?.tags?.map((item) => (
                                                <div
                                                  key={item}
                                                  className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                                >
                                                  <p>{item}</p>
                                                </div>
                                              ))}
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() => setSeeMoreTags()}
                                              >
                                                See Less
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              {item?.tags
                                                ?.slice(0, 3)
                                                ?.map((item) => (
                                                  <div
                                                    key={item}
                                                    className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                                  >
                                                    <p>{item}</p>
                                                  </div>
                                                ))}
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreTags(item?.id)
                                                }
                                              >
                                                See More
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.tags?.map((item) => (
                                            <div
                                              key={item}
                                              className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                            >
                                              <p>{item}</p>
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex items-center flex-wrap gap-3 w-fit">
                                      {item?.redirect?.length <= 0 ? (
                                        <div
                                          className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                          title="Please select any redirect"
                                        >
                                          <TriangleAlert className="size-4" />{" "}
                                          Action Required
                                        </div>
                                      ) : (
                                        item?.redirect?.map((e) => (
                                          <React.Fragment key={e}>
                                            {reviewPlatforms(e)}
                                          </React.Fragment>
                                        ))
                                      )}
                                    </div>
                                  </TableCell>
                                  {/* <TableCell>
                                    <div className="w-[90px]">
                                      {item?.default_set
                                        ? questionSets?.find(
                                            (set) =>
                                              set?.id === item?.default_set
                                          )?.name === "default"
                                          ? "Default"
                                          : questionSets?.find(
                                              (set) =>
                                                set?.id === item?.default_set
                                            )?.name
                                        : "Default"}
                                    </div>
                                  </TableCell> */}
                                  <TableCell className="w-[50px]">
                                    <p className="text-center">
                                      {item?.reviews_count || 0}
                                    </p>
                                  </TableCell>

                                  <TableCell className="w-[180px]">
                                    <p className="text-center">
                                      {item?.verified_reviews || 0}
                                    </p>
                                  </TableCell>

                                  <TableCell>
                                    <div className="flex items-center">
                                      {/* <p className="w-24">{item?.employee_code}</p> */}
                                      <IconButton title={item?.employee_code}>
                                        <Link
                                          className={`w-5 h-5 cursor-pointer text-[#888] ${!isActiveStaffManagement && "cursor-not-allowed"}`}
                                          onClick={() => {
                                            setIsOpenLink(item?.id);
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton>
                                        <QrCode
                                          className="w-5 h-5 cursor-pointer text-[#888]"
                                          onClick={() =>
                                            handleGetQr(item?.employee_code)
                                          }
                                        />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      className={`flex items-center gap-x-3 ${i === 0 && "activate-magiclink"}`}
                                    >
                                      <Switch
                                        disabled={
                                          isToggleEmployeeLoading ||
                                          !isActiveStaffManagement
                                        }
                                        checked={item?.is_enable}
                                        onCheckedChange={() =>
                                          toggleEmployee(item?.id)
                                        }
                                        // className={`${item?.is_enable ? "bg-[#00b67a]" : "bg-[#8E8B8B]"} relative h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                                      ></Switch>
                                      {isToggleEmployeeLoading &&
                                        item?.id === employeeId && (
                                          <div className="flex items-center justify-center">
                                            <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                          </div>
                                        )}
                                    </div>
                                  </TableCell>

                                  {isActiveStaffManagement && (
                                    <TableCell>
                                      <DropdownMenu>
                                        <DropdownMenuTrigger>
                                          <IconButton>
                                            <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                          </IconButton>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                          <DropdownMenuItem
                                            className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() =>
                                              handleDefaultMagicLink(item?.id)
                                            }
                                          >
                                            <input
                                              type="radio"
                                              name="default"
                                              className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"} w-4 h-4`}
                                              id={item?.id}
                                              checked={
                                                item?.id === active?.employee_id
                                              }
                                            />
                                            Make a default
                                          </DropdownMenuItem>
                                          <DropdownMenuItem
                                            className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() => {
                                              handleCopyId(item?.employee_code);
                                            }}
                                          >
                                            <IconButton>
                                              <Copy className="w-4 h-4 cursor-pointer" />
                                            </IconButton>
                                            Copy Id
                                          </DropdownMenuItem>
                                          <DropdownMenuItem
                                            className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() => {
                                              setIsOpenEditStaffModal(item?.id);
                                            }}
                                          >
                                            <IconButton>
                                              <Pencil
                                                className={`w-4 h-4 cursor-pointer ${i === 0 && "edit-magiclink"}`}
                                              />
                                            </IconButton>
                                            Edit
                                          </DropdownMenuItem>
                                          {item?.id !== active?.employee_id && (
                                            <DropdownMenuItem
                                              className="flex items-center gap-2  pr-4 text-[15px] text-red-500 hover:text-red-500"
                                              onClick={() => {
                                                setIsOpenDeleteModal(true);
                                                setDeleteId(item?.id);
                                              }}
                                            >
                                              {item?.id !==
                                                active?.employee_id && (
                                                <IconButton>
                                                  <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                                                </IconButton>
                                              )}
                                              Delete
                                            </DropdownMenuItem>
                                          )}
                                        </DropdownMenuContent>
                                      </DropdownMenu>
                                      {/* <div className="flex justify-end items-center text-[#888]">
                                        <IconButton>
                                          <Pencil
                                            className={`w-5 h-5 cursor-pointer ${i === 0 && "edit-magiclink"}`}
                                            onClick={() => {
                                              setIsOpenEditStaffModal(item?.id);
                                              // setToUpdateData(item);
                                            }}
                                          />
                                        </IconButton>

                                        {item?.id !== active?.employee_id && (
                                          <IconButton>
                                            <Trash2
                                              className="w-5 h-5 cursor-pointer text-red-500"
                                              onClick={() => {
                                                setIsOpenDeleteModal(true);
                                                setDeleteId(item?.id);
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </div> */}
                                    </TableCell>
                                  )}
                                </TableRow>
                                {item?.id === isOpenEditStaffModal && (
                                  <EditStaffModal
                                    openModal={
                                      item?.id === isOpenEditStaffModal
                                    }
                                    setOpenModal={setIsOpenEditStaffModal}
                                    refetch={refetch}
                                    entityId={userInfo?.entity?.id}
                                    key={item?.id}
                                    data={item}
                                    // setData={setToUpdateData}
                                  />
                                )}
                                {item?.id === isOpenLink && (
                                  <LinkModal
                                    openModal={item?.id === isOpenLink}
                                    setOpenModal={setIsOpenLink}
                                    link={
                                      baseUrl +
                                      "/" +
                                      userInfo?.entity?.magic_link?.split(
                                        "?"
                                      )?.[0] +
                                      `?employee=${item?.employee_code}`
                                    }
                                  />
                                )}
                              </>
                            ))}
                          {data
                            ?.filter((item) => !item?.is_enable)
                            ?.filter(
                              (item) => item?.default_set === selectedSet
                            )
                            ?.map((item) => (
                              <>
                                <TableRow
                                  className="bg-white text-[#777] dark:bg-darkBg"
                                  key={item?.id}
                                >
                                  {/* <TableCell>
                                    <div className="flex items-center gap-x-2">
                                      <input
                                        type="radio"
                                        name="default"
                                        className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary  ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"}`}
                                        id={item?.id}
                                        onChange={() =>
                                          handleDefaultMagicLink(item?.id)
                                        }
                                        checked={
                                          item?.id === active?.employee_id
                                        }
                                      />
                                      <p className="text-[#777]">
                                        {item?.employee_code}
                                      </p>
                                    </div>
                                  </TableCell> */}
                                  <TableCell>
                                    <p className="text-[#777]">{item?.name}</p>
                                  </TableCell>

                                  <TableCell>
                                    <div className="max-w-[120px] text-[#777]">
                                      {item?.label?.length > 14 ? (
                                        <>
                                          {seeMoreLabel === item?.id ? (
                                            <div>
                                              <p className="break-words">
                                                {item?.label}
                                              </p>
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreLabel()
                                                }
                                              >
                                                See Less
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p className="line-clamp-1">
                                                {item?.label?.slice(0, 14)}...
                                              </p>
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreLabel(item?.id)
                                                }
                                              >
                                                See More
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <p className="break-words">
                                          {item?.label}
                                        </p>
                                      )}
                                    </div>
                                  </TableCell>

                                  <TableCell>
                                    <div className="flex items-center gap-1 w-[100px] flex-wrap text-[#777]">
                                      {item?.tags?.length > 3 ? (
                                        <>
                                          {seeMoreTags === item?.id ? (
                                            <>
                                              {item?.tags?.map((item) => (
                                                <div
                                                  key={item}
                                                  className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                                >
                                                  <p>{item}</p>
                                                </div>
                                              ))}
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() => setSeeMoreTags()}
                                              >
                                                See Less
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              {item?.tags
                                                ?.slice(0, 3)
                                                ?.map((item) => (
                                                  <div
                                                    key={item}
                                                    className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                                  >
                                                    <p>{item}</p>
                                                  </div>
                                                ))}
                                              <p
                                                className="text-secondary cursor-pointer hover:underline text-sm"
                                                onClick={() =>
                                                  setSeeMoreTags(item?.id)
                                                }
                                              >
                                                See More
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.tags?.map((item) => (
                                            <div
                                              key={item}
                                              className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                            >
                                              <p>{item}</p>
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex items-center w-fit flex-wrap gap-3">
                                      {item?.redirect?.length <= 0 ? (
                                        <div
                                          className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                          title="Please select any redirect"
                                        >
                                          <TriangleAlert className="size-4" />{" "}
                                          Action Required
                                        </div>
                                      ) : (
                                        item?.redirect?.map((e) => (
                                          <React.Fragment key={e}>
                                            {reviewPlatforms(e)}
                                          </React.Fragment>
                                        ))
                                      )}
                                    </div>
                                  </TableCell>
                                  {/* <TableCell>
                                    <p className={"text-[#777] w-fit"}>
                                      {item?.default_set
                                        ? questionSets?.find(
                                            (set) =>
                                              set?.id === item?.default_set
                                          )?.name === "default"
                                          ? "Default"
                                          : questionSets?.find(
                                              (set) =>
                                                set?.id === item?.default_set
                                            )?.name
                                        : "Default"}
                                    </p>
                                  </TableCell> */}
                                  <TableCell className="w-[50px]">
                                    <p className={"text-[#777] text-center"}>
                                      {item?.reviews_count || 0}
                                    </p>
                                  </TableCell>

                                  <TableCell className="w-[180px]">
                                    <p className="text-center">
                                      {item?.verified_reviews || 0}
                                    </p>
                                  </TableCell>

                                  <TableCell>
                                    <div className="flex items-center">
                                      {/* <p className="w-24">{item?.employee_code}</p> */}
                                      <IconButton title={item?.employee_code}>
                                        <Link
                                          className={`w-5 h-5 cursor-pointer text-[#888]`}
                                          onClick={() => {
                                            // handleCopy(item?.employee_code)

                                            setIsOpenLink(item?.id);
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton>
                                        <QrCode
                                          className="w-5 h-5 cursor-pointer text-[#888]"
                                          onClick={() =>
                                            handleGetQr(item?.employee_code)
                                          }
                                        />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div className="flex items-center gap-x-3">
                                      <Switch
                                        disabled={
                                          isToggleEmployeeLoading ||
                                          !isActiveStaffManagement
                                        }
                                        checked={item?.is_enable}
                                        onCheckedChange={() =>
                                          toggleEmployee(item?.id)
                                        }
                                        // className={`${item?.is_enable ? "bg-[#00b67a]" : "bg-[#8E8B8B]"} relative h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                                      ></Switch>
                                      {isToggleEmployeeLoading &&
                                        item?.id === employeeId && (
                                          <div className="flex items-center justify-center">
                                            <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                          </div>
                                        )}
                                    </div>
                                  </TableCell>
                                  {isActiveStaffManagement && (
                                    <TableCell>
                                      <DropdownMenu>
                                        <DropdownMenuTrigger>
                                          <IconButton>
                                            <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                          </IconButton>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                          <DropdownMenuItem
                                            className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() =>
                                              handleDefaultMagicLink(item?.id)
                                            }
                                          >
                                            <input
                                              type="radio"
                                              name="default"
                                              className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"} w-4 h-4`}
                                              id={item?.id}
                                              checked={
                                                item?.id === active?.employee_id
                                              }
                                            />
                                            Make a default
                                          </DropdownMenuItem>
                                          <DropdownMenuItem
                                            className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() => {
                                              handleCopyId(item?.employee_code);
                                            }}
                                          >
                                            <IconButton>
                                              <Copy className="w-4 h-4 cursor-pointer" />
                                            </IconButton>
                                            Copy Id
                                          </DropdownMenuItem>
                                          <DropdownMenuItem
                                            className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                            onClick={() => {
                                              setIsOpenEditStaffModal(item?.id);
                                            }}
                                          >
                                            <IconButton>
                                              <Pencil className="w-4 h-4 cursor-pointer" />
                                            </IconButton>
                                            Edit
                                          </DropdownMenuItem>
                                          <DropdownMenuItem
                                            className="flex items-center gap-2 pr-4 text-[15px] text-red-500 hover:text-red-500"
                                            onClick={() => {
                                              setIsOpenDeleteModal(true);
                                              setDeleteId(item?.id);
                                            }}
                                          >
                                            {item?.id !==
                                              active?.employee_id && (
                                              <IconButton>
                                                <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                                              </IconButton>
                                            )}
                                            Delete
                                          </DropdownMenuItem>
                                        </DropdownMenuContent>
                                      </DropdownMenu>
                                      {/* <div className="flex justify-end items-center text-[#888]">
                                        <IconButton>
                                          <Pencil
                                            className="w-5 h-5 cursor-pointer"
                                            onClick={() => {
                                              setIsOpenEditStaffModal(item?.id);
                                            }}
                                          />
                                        </IconButton>

                                        {item?.id !== active?.employee_id && (
                                          <IconButton>
                                            <Trash2
                                              className="w-5 h-5 cursor-pointer text-red-500"
                                              onClick={() => {
                                                setIsOpenDeleteModal(true);
                                                setDeleteId(item?.id);
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </div> */}
                                    </TableCell>
                                  )}
                                </TableRow>
                                {item?.id === isOpenEditStaffModal && (
                                  <EditStaffModal
                                    openModal={
                                      item?.id === isOpenEditStaffModal
                                    }
                                    setOpenModal={setIsOpenEditStaffModal}
                                    refetch={refetch}
                                    entityId={userInfo?.entity?.id}
                                    key={item?.id}
                                    data={item}
                                    // setData={setToUpdateData}
                                  />
                                )}
                                {item?.id === isOpenLink && (
                                  <LinkModal
                                    openModal={item?.id === isOpenLink}
                                    setOpenModal={setIsOpenLink}
                                    link={
                                      baseUrl +
                                      "/" +
                                      userInfo?.entity?.magic_link?.split(
                                        "?"
                                      )?.[0] +
                                      `?employee=${item?.employee_code}`
                                    }
                                  />
                                )}
                              </>
                            ))}
                        </>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <MagiclinkIllustrations />
              )}
            </div>
          </>
        )}

        {/* <div className="w-full lg:w-[630px] flex items-center justify-between mt-7">
          {index > 0 ? (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handlePrev}
            >
              Prev
            </button>
          ) : (
            <div></div>
          )}
          {data?.total > 0 && index + 10 < data?.total && (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handleNext}
            >
              Next
            </button>
          )}
        </div> */}
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      {isOpenAddStaffModal && (
        <AddStaffModal
          openModal={isOpenAddStaffModal}
          setOpenModal={setIsOpenAddStaffModal}
          refetch={refetch}
          entityId={userInfo?.entity?.id}
          key={toUpdateData || uuidv4()}
          data={toUpdateData}
          setData={setToUpdateData}
          selectedSet={selectedSet}
        />
      )}
      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={"Are you sure you want to delete this link?"}
        handleDelete={handleDeleteEmployee}
        isLoading={deleteEmployeeLoading}
      />
      <QrContainer
        openEditModal={isOpenQrContainer}
        setOpenEditModal={setIsOpenQrContainer}
        qr={qr}
        isLoading={getMagicQrLoading}
      />
    </Layout>
  );
};

export default Staffs;
