/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import {
  useDeleteTargetLocation,
  useGetTargetLocations,
  usePostAddTargetLocations,
  usePostSearchTargetLocation,
} from "../../../../query/useReview";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { Search, Trash2 } from "lucide-react";
import { toast } from "sonner";
import LoaderIcon from "react-loader-icon";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";

const TargetLocationModal = ({ openModal, setOpenModal }) => {
  const [step, setStep] = useState(1);
  const [search, setSearch] = useState("");
  const [searchLocations, setSearchLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const { data: targetLocations, refetch: refetchTargetLocations } =
    useGetTargetLocations();
  const {
    mutate: deleteTargetLocation,
    isLoading: deleteTargetLocationLoading,
  } = useDeleteTargetLocation();

  const {
    mutate: searchTargetLocation,
    isLoading: searchTargetLocationLoading,
  } = usePostSearchTargetLocation();

  const { mutate: addTargetLocation, isLoading: addTargetLocationLoading } =
    usePostAddTargetLocations();

  const handleSearchTargetLocation = () => {
    searchTargetLocation(
      { search: search },
      {
        onSuccess: (res) => {
          setSearchLocations(res);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleAddTargetLocation = () => {
    addTargetLocation(
      {
        resource_id: selectedLocation?.resource_name?.split("/")?.[1],
        name: selectedLocation?.name,
        country_code: selectedLocation?.country_code,
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchTargetLocations();
          setSearch("");
          setSearchLocations([]);
          setSelectedLocation(null);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleDeleteTargetLocation = (id) => {
    setDeleteId(id);
    deleteTargetLocation(id, {
      onSuccess: (res) => {
        toast.success(res?.message);
        refetchTargetLocations();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something went wrong. Please try again later.");
        }
      },
    });
  };

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="" isOverlay={false}>
        <DialogHeader>
          <DialogTitle>Target Locations</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5 font-pro min-h-[50vh] max-h-[80vh] overflow-y-auto scrollbar-thin">
          {/* {step === 1 && (
            <>
              <Button onClick={() => setStep(2)}>Add Target Location</Button> */}
          <div className="flex flex-col gap-5">
            <div className="space-y-3">
              <label htmlFor="add">Search Target Locations</label>
              <div className="flex items-center gap-3">
                <Input
                  id="add"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  size="icon"
                  className="flex-shrink-0"
                  onClick={handleSearchTargetLocation}
                >
                  <Search />
                </Button>
              </div>
            </div>
            {searchTargetLocationLoading ? (
              <div className="text-center mt-10">
                <LoaderIcon type="cylon" color="#7190FF" />
              </div>
            ) : searchLocations?.length > 0 ? (
              <div className="space-y-3">
                <RadioGroup
                  defaultValue={selectedLocation}
                  onValueChange={(e) => setSelectedLocation(e)}
                >
                  {searchLocations?.map((item) => (
                    <div key={item?.resource_name} className="flex gap-3">
                      <RadioGroupItem
                        value={item}
                        id={item?.resource_name}
                        className="rounded"
                        isSquare
                      ></RadioGroupItem>
                      <label
                        htmlFor={item?.resource_name}
                        className={`w-full border cursor-pointer grid grid-cols-1 lg:grid-cols-2 gap-3 p-5  dark:bg-darkLightBg rounded-[10px] ${selectedLocation?.resource_name === item?.resource_name ? "bg-[#f0f5ff] border-newPrimary" : ""}`}
                      >
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            Name
                          </p>
                          <Input
                            value={item?.name}
                            readOnly
                            autoFocus={false}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            Country Code
                          </p>
                          <Input
                            value={item?.country_code}
                            readOnly
                            autoFocus={false}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            Target Type
                          </p>
                          <Input
                            value={item?.target_type}
                            readOnly
                            autoFocus={false}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            Reach
                          </p>
                          <Input
                            value={item?.reach}
                            readOnly
                            autoFocus={false}
                          />
                        </div>
                      </label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            ) : (
              <p className="italic text-center text-sm text-darkGray">
                No result to show!
              </p>
            )}
          </div>
          <div className="flex justify-end gap-3">
            <Button onClick={() => setOpenModal(false)} variant="secondary">
              Back
            </Button>
            <Button
              onClick={handleAddTargetLocation}
              disabled={addTargetLocationLoading || !selectedLocation}
            >
              Add
            </Button>
          </div>{" "}
          <div className="rounded-xl overflow-hidden border">
            <Table>
              <TableHeader>
                <TableRow className="">
                  {["Resource Id", "Country Name", "Country Code", ""].map(
                    (item) => (
                      <TableHead
                        key={item}
                        className="text-xs text-[#888] font-medium"
                      >
                        {item}
                      </TableHead>
                    )
                  )}
                </TableRow>
              </TableHeader>
              <TableBody className="divide-y">
                {targetLocations?.map((item) => (
                  <TableRow className="text-[15px]" key={item?.id}>
                    <TableCell>{item?.resource_id}</TableCell>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.country_code}</TableCell>
                    <TableCell>
                      <Trash2
                        className={`text-red-500 size-5 ${deleteId === item?.id ? "cursor-progress" : " cursor-pointer"}`}
                        onClick={() => {
                          handleDeleteTargetLocation(item?.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {/* </>
          )} */}
          {/* <div className="space-y-3">
            <label htmlFor="add">Seo Setting Count</label>
            <Input
              id="add"
              placeholder="Seo Setting Count"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div> */}
          {/* {step === 2 && (
            
          )} */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TargetLocationModal;
