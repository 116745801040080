/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import AudioPlayerForRaReviewForm from "./AudioPlayerForRaReviewForm";

const RaViewFormModal = ({ isOpen, setIsOpen, data }) => {
  const restructuredData =
    data?.questions_responses &&
    data?.questions_responses?.reduce((acc, item) => {
      const category = item?.category;
      if (!acc[category]) {
        acc[category] = [];
      }

      if (item?.keywords?.length > 0) {
        item?.keywords?.forEach((keyword) => {
          if (!acc[category][keyword]) {
            acc[category][keyword] = [];
          }
          acc[category][keyword].push({
            keywords: item.keywords,
            question: item.question,
            question_id: item.question_id,
            response_options: item.response_options,
            selected_response: item.selected_response,
          });
        });
      } else {
        if (!acc[category][null]) {
          acc[category][null] = [];
        }
        acc[category][null].push({
          keywords: item.keywords,
          question: item.question,
          question_id: item.question_id,
          response_options: item.response_options,
          selected_response: item.selected_response,
        });
      }

      return acc;
    }, {});

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen()}>
      <DialogContent className="lg:max-w-7xl">
        <DialogHeader className={"hidden"}>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        <div className="max-h-[80dvh] overflow-y-auto scrollbar-thin px-6 mt-6 font-pro space-y-5">
          <div>
            <p className="font-medium text-[24px] font-pro -tracking-[1px] leading-[36px]">
              Magiclink Used
            </p>
            <div className="flex items-center justify-between gap-5">
              <p className="text-[#4A4A4A] dark:text-white">
                {data?.magiclink_used}
              </p>
              {data?.track_id && (
                <p className="font-semibold">
                  <span className="font-semibold">Track ID </span>-{" "}
                  {data?.track_id}
                </p>
              )}
            </div>
          </div>
          {data?.voice_review ? (
            <div className="">
              <p className="font-medium text-[24px] font-pro -tracking-[1px] leading-[36px]">
                Recorded Audio
              </p>
              <AudioPlayerForRaReviewForm src={data?.original_voice?.voice} />
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              {Object?.keys(restructuredData)?.map((category) => (
                <div key={category} className="py-3">
                  <div className="flex flex-wrap gap-3 md:w-1/2">
                    <div
                      className={`py-1.5 rounded-lg w-fit h-fit bg-[#e1e8fe] font-pro text-[13px] font-normal text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                    >
                      Category
                    </div>
                    <div
                      className={`py-1.5 rounded-lg w-fit h-fit bg-[#ffaf19]/25  font-pro text-[13px] font-normal text-center px-5 dark:bg-[#262626] dark:text-[#ffc200]`}
                    >
                      {category !== "null" ? category : "-"}
                    </div>
                  </div>
                  <div>
                    {Object.keys(restructuredData[category])?.map((keyword) => (
                      <div
                        key={keyword}
                        className="p-5 bg-[#fafafa] dark:bg-darkBg border rounded-xl mt-5"
                      >
                        {keyword !== "null" && (
                          <div className="flex flex-wrap gap-3 items-center">
                            <div
                              className={`py-1.5 rounded-lg w-fit bg-[#e1e8fe] font-pro text-[13px] font-normal text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                            >
                              Keyword
                            </div>

                            <div
                              className={`py-1.5 rounded-lg w-fit bg-[#FD5E3B]/25  font-pro text-[13px] font-normal text-center px-5 dark:bg-[#262626] dark:text-[#ff6464]`}
                            >
                              {keyword}
                            </div>
                          </div>
                        )}
                        {restructuredData[category][keyword]?.map((item, i) => (
                          <div
                            className="flex items-center gap-x-5 mt-5"
                            key={item?.question_id}
                          >
                            <div className=" w-full flex items-center gap-x-5 select-none">
                              <div className="w-full">
                                <div className="flex flex-row justify-between items-start pb-4 w-full">
                                  <p className="text-[#333] dark:text-white font-pro font-medium text-base break-words">
                                    Q.{i + 1}{" "}
                                    {item?.question ? item?.question : "-"}
                                  </p>
                                </div>
                                <div className=" py-2 grid grid-cols-1 md:grid-cols-2 lg:flex gap-[19px] lg:flex-wrap">
                                  {item?.response_options?.map((answer, i) => (
                                    <button
                                      key={answer}
                                      className={`flex items-center px-4 py-2 border rounded-lg text-[#4A4A4A] dark:text-white ${
                                        answer === item?.selected_response
                                          ? "bg-secondary/30 border-secondary"
                                          : "border-[#bbb]"
                                      }`}
                                    >
                                      <span
                                        className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2  ${
                                          answer === item?.selected_response
                                            ? "bg-secondary border-secondary"
                                            : "border-[#bbb]"
                                        }`}
                                      >
                                        {answer === item?.selected_response ? (
                                          <svg
                                            width="12"
                                            height="10"
                                            viewBox="0 0 12 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                              stroke="white"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {answer}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RaViewFormModal;
